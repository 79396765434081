// @utility
import { setStorage, removeStorage } from "@utility/app/storage";
import alertText from "@utility/app/alertText";

// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setSnackbarData } from "@core/redux/actions/app/snackbar";
import { loadHomeData } from "@core/redux/actions/home/index";
import { setDataAlert } from "@core/redux/actions/app/alert";

const LOAD_DATA_DEPOSIT = 'app/deposit/LOAD_DATA_DEPOSIT';
const LOAD_BANK_CUSTOMER = 'app/deposit/LOAD_BANK_CUSTOMER';

function loadDataDeposit() {
    return dispatch => {
        apiServices({}, "get", "/customer/payment/deposit/channel").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_DATA_DEPOSIT,
                    payload: {
                        items: result
                    }
                });

                if (data.result.mode === "pay") {
                    setStorage("processDeposit", 1);
                }
                else if (data.result.mode === "deposit") {
                    dispatch(loadBankCustomer());
                }
            }
        });
    }
}

function loadBankCustomer() {
    return dispatch => {
        apiServices({}, "get", "/customer/payment/deposit/bank/customer/select-list").then((data) => {
            const { code: statusCode, result } = data;

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_BANK_CUSTOMER,
                    payload: {
                        items: result
                    }
                });
            }
        });
    }
}

function confirmDeposit(data, setMenuSelect) {
    return dispatch => {
        const {
            amount,
            bankId,
            channel,
            channelId
        } = data;

        let methodType = 4;
        let selectType = 1;

        if (channel === "qrcode" || channel === "akpay" || channel === "dypay" || channel === "payin") {
            methodType = 5;
            selectType = 3;
        }
        else if (channel === "truemoney") {
            methodType = 6;
            selectType = 2;
        }
        else if (channel === "bank") {
            methodType = 4;
        }
        else {
            methodType = 0;
        }

        const dataJson = {
            "amount": amount,
            "bankId": bankId,
            "method": methodType,
            channel,
            channelId,
            "select": selectType
        };

        removeStorage("qrcodeUrl");
        removeStorage("ifameMode");
        removeStorage("depositPromptPayTime");
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        apiServices(dataJson, "post", "/customer/payment/deposit/channel").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));
            setMenuSelect(channel);

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDepositData(result));
                setStorage("processDeposit", 1);

                if (methodType === 4) {
                    setMenuSelect("pending");
                }
                else {
                    if (methodType === 5) {
                        setStorage("qrcodeUrl", result?.pay_url);
                        setStorage("ifameMode", result?.iframe_mode);
                        let firstEventTimestamp = Date.now();
                        let timeStart = firstEventTimestamp + (5 * 60 * 1000);

                        setStorage("depositPromptPayTime", timeStart);
                    }
                }
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function cancelDeposit(setMenuSelect) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        apiServices({}, "post", "/customer/payment/deposit/cancel").then((data) => {
            const { code: statusCode } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadDataDeposit());
                removeStorage("processDeposit");
                removeStorage("qrcodeUrl");
                removeStorage("ifameMode");
                removeStorage("depositPromptPayTime");

                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "ยกเลิกรายการเติมเงินสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));

                setMenuSelect("home");
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function checkDeposit(setMenuSelect, checkTime, depositId, typePage) {
    return dispatch => {
        apiServices({ "id": depositId }, "post", "/customer/payment/deposit/check").then((data) => {
            const { code: statusCode } = data;

            if (statusCode === 200 || statusCode === 0) {
                switch (data.result.status) {
                    case 0:
                    case 1: break;
                    case 2:

                        if (typePage === "deposit") {
                            dispatch(setDataAlert({
                                type: "success",
                                title: "แจ้งเตือน",
                                text: "ทำรายการฝากเงินสำเร็จ !",
                                action: true,
                                redirect: null,
                                show: true,
                                buttonText: "ตกลง"
                            }));
                        }
                        else {
                            dispatch(setSnackbarData({
                                statusShow: true,
                                statusAlert: "success",
                                titleAlert: "แจ้งเตือน",
                                textAlert: "ทำรายการฝากเงินสำเร็จ !"
                            }));
                        }

                        dispatch(loadDataDeposit());
                        dispatch(loadHomeData());
                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        setMenuSelect("home");
                        break;
                    case 3:
                        dispatch(loadDataDeposit());
                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        setMenuSelect("home");
                        break;
                    default:
                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        break;
                }
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setSnackbarData(snackbarJson));
            }
        });
    }
}

function confirmStep(data) {
    return dispatch => {
        const {
            td_id,
            promotion_status,
            promotion_condition_id
        } = data;

        let myJson = {
            "td_id": td_id,
            "promotion_status": promotion_status,
            "promotion_condition_id": promotion_condition_id
        };

        dispatch(setStatusSpinner({ status: true, textProcess: null }));
        apiServices(myJson, "post", "/customer/payment/deposit/confirm").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadDataDeposit());

                if (result !== null) {
                    if (result?.status === 2) {
                        dispatch(setDataAlert({
                            type: "success",
                            title: "แจ้งเตือน",
                            text: "ทำรายการเติมเงินสำเร็จ !",
                            action: true,
                            redirect: null,
                            show: true,
                            buttonText: "ตกลง"
                        }));

                        removeStorage("processDeposit");
                    }
                }
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function confirmPaySlip(data) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        apiServices(data, "file", "/customer/payment/deposit/slip").then((data) => {
            const { code: statusCode } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadDataDeposit());

                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "ส่งข้อมูลเพิ่มเติมสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function confirmCheckSlip(data) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        apiServices(data, "file", "/customer/payment/deposit/verify-slip").then((data) => {
            const { code: statusCode, message } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadDataDeposit());

                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "อัพโหลดข้อมูลสลิปเติมเงินสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
            else {
                if (statusCode === 99) {
                    dispatch(setDataAlert({
                        type: "warning",
                        title: "แจ้งเตือน",
                        text: message,
                        action: true,
                        redirect: null,
                        show: true,
                        buttonText: "ตกลง"
                    }));
                }
                else {
                    let snackbarJson = alertText(statusCode);
                    dispatch(setDataAlert({
                        type: snackbarJson.statusAlert,
                        title: snackbarJson.titleAlert,
                        text: snackbarJson.textAlert,
                        action: true,
                        redirect: null,
                        show: true,
                        buttonText: "ตกลง"
                    }));
                }
            }
        });
    }
}


function setDepositData(data) {
    return {
        type: LOAD_DATA_DEPOSIT,
        payload: {
            items: data
        }
    }
}

export {
    LOAD_DATA_DEPOSIT,
    LOAD_BANK_CUSTOMER,
    loadDataDeposit,
    loadBankCustomer,
    setDepositData,
    confirmDeposit,
    cancelDeposit,
    checkDeposit,
    confirmStep,
    confirmPaySlip,
    confirmCheckSlip
}